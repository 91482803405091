.notifications {
	@include container-default($flex-direction: row);
	&__icon {
		margin-top: -2rem;
		&__amount-bubble {
			position: relative;
			top: 0;
			@include container-default();
			border-radius: 50%;
			background-color: $red;

			width: 2rem;
			height: 2rem;
			margin-top: -4rem;
			margin-left: 3rem;

			p {
				color: $white;
				font-size: 1rem;
				margin-bottom: 0;
			}
			&.hide {
				background-color: transparent;
				p {
					color: transparent;
				}
			}
		}
		.MuiSvgIcon-root {
			font-size: 3rem;
			color: $dark-blue;
		}
	}
	&__list {
		position: absolute;
		top: 5rem;
		right: 3rem;
		@include box-shadow-default();
		background-color: $white;
		z-index: 10001;
		width: 50rem;
		overflow-y: auto;
		overflow-x: hidden;
		max-height: 50rem;
		p {
			margin-bottom: 0;
		}

		&__item {
			cursor: pointer;
			padding: 1rem;
			&.not-seen {
				background-color: $light-gray-border;
			}
			border-bottom: $divider-color solid 1px;
			&:last-child {
				border-bottom: none;
			}
			&__clear-all {
				text-align: center;
			}
			&__created-date {
				font-size: 0.8rem;
				color: $dark-blue;
				margin-bottom: 0.5rem;
			}
			&__row {
				@include container-default(
					$flex-direction: row,
					$align-items: center,
					$justify-content: space-between
				);
				&__text {
					margin-right: 1rem;
				}
			}
			&__details {
				border-top: 1px $divider-color solid;
				p {
					margin: 1rem 0 0 0;
					font-size: 1.2rem;
				}
			}
		}
	}
}
