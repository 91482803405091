.content-creator-page {
	height: 100%;
	width: 100%;
	@include container-default(
		$flex-direction: column,
		$justify-content: flex-start
	);
	&__content {
		-ms-overflow-style: none; /* Internet Explorer 10+ */
		scrollbar-width: none; /* Firefox */
		@include box-shadow-light();
		background-color: $light-gray;
		margin: 2rem 1.5rem;
		//padding: 1rem 0rem;
		height: 90%;
		width: 95%;
		background-color: $white;
		margin-bottom: 2rem;
		border-radius: 1.4rem;
		overflow-y: hidden;
		overflow-x: hidden;
	}
	&__content::-webkit-scrollbar {
		display: none; /* Chrome */
	}
}
