.project-settings-page {
	background-color: $background-gray;
	width: 100%;
	height: calc(100vh - 15vh);

	&__project-notes-modal {
		@include container-default($justify-content: flex-start);
		min-height: 70%;
		width: 80%;
		margin: 0 auto;
		h2 {
			margin-bottom: 2rem;
		}
		&__notes-container {
			@include container-default(
				$flex-direction: row,
				$justify-content: center,
				$align-items: flex-start
			);
			width: 100%;
			textarea {
				margin-bottom: 0;
				min-width: 100%;
			}
			&__text {
				color: $primary;
			}
		}
		&__buttons {
			@include container-default($flex-direction: row);
			margin-top: auto;
		}
	}
	&__ads-modal {
		@include container-default(
			$flex-direction: row,
			$align-items: center,
			$justify-content: center
		);
		&.more-accounts {
			width: 80%;
		}

		margin: 2rem;
		flex-wrap: wrap;
		p {
			width: 100%;
			margin-bottom: 0;
			font-weight: 600;
			margin-left: 1.25rem;
			font-size: 1.4rem;
		}
		&--button {
			margin-top: 2rem;
			width: 30%;
			margin-right: auto;
		}
		&--divider {
			margin-top: 1rem;
			margin-bottom: 2rem;
			width: 98%;
			border-bottom: 0.5px solid $light-gray-border;
		}
		&--manual-connect {
			@include container-default(
				$flex-direction: row,
				$align-items: center,
				$justify-content: flex-start
			);
		}
		&--title {
			margin-bottom: 1rem;
		}
		&--description {
			margin-bottom: 1rem;
		}
	}
	&__wordpress-modal {
		@include container-default(
			$flex-direction: row,
			$align-items: center,
			$justify-content: center
		);

		margin: 2rem;
		flex-wrap: wrap;
		p {
			width: 100%;
			margin-bottom: 0;
			font-weight: 600;
			margin-left: 1.25rem;
			font-size: 1.4rem;
		}
		&--input-container {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			width: 100%;
			&--button {
				width: 30%;
				margin-right: auto;
			}
		}
	}
	.box-row {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		.input__container {
			margin-top: unset;
			padding-top: unset;
			p {
				margin-top: unset;
				padding-top: unset;
				margin-left: unset;
				span {
					width: 15rem;
				}
			}
			.input__row {
				width: 50%;
			}
		}
		.url-filter-input {
			.input__container {
				.input__row {
					width: 100%;
				}
			}
		}
		&.conversion {
			max-width: 100%;
			justify-content: flex-start;
			.input__container {
				padding-left: 0;
				margin-left: 0;
				p {
					padding-left: 0;
				}
				.input__row {
					width: 63%;
				}
			}
		}
		.button {
			margin: 1% 5% 2.8rem 5%;
			align-self: flex-end;
		}
		.box-column {
			margin: 1rem;
			display: flex;
			flex-direction: column;
		}
		.box-right-side {
			background-color: $background-gray;
			margin-right: 2rem;
			max-width: 50%;
			.input__label {
				font-size: 1.4rem;
				color: $modal-opacity;
			}
			max-height: 88vh;
			box-shadow: 7px -7px 10px -10px rgba(59, 94, 218, 0.07),
				-5px 2px 9px -9px rgba(59, 94, 218, 0.05);
		}
		.box-left-side {
			background-color: $background-gray;
			margin-left: 2rem;
			min-width: 40%;
			.MuiPaper-root {
				margin-top: 1.5rem;
			}
			overflow-y: scroll;
			max-height: 92vh;
			margin-right: 2rem;
			box-shadow: 7px -7px 10px -10px rgba(59, 94, 218, 0.07),
				-5px 2px 9px -9px rgba(59, 94, 218, 0.05);
		}
		.box {
			padding: 0rem 1rem 1rem 1rem;
			box-shadow: none;
			.setting-page__right-panel-half-group {
				margin-left: 1rem;
				align-items: flex-start;
				.notification-buttons .button-container {
					margin: $padding-small;
				}
				&.sticky {
					position: sticky;
					top: 0;
					margin: 1rem 0 1rem 0;
					.box-title {
						margin-top: 1rem;
					}
				}
				&.advanced-settings {
					margin-left: unset;
					justify-content: flex-start;
					align-items: center;
					h6 {
						margin-right: unset;
					}
				}
				&.delete-project {
					display: flex;
					flex-direction: column;
					align-items: flex-end;
					p {
						margin-right: 1rem;
						margin-left: auto;
						width: 23rem;
					}
					.button-container {
						margin-top: 1rem;
					}
				}
				&--goals {
					.box-sub-title {
						padding-left: 0;
					}
					&--ga4-events {
						display: flex;
						flex-direction: row;
						width: 100%;
						&--details {
							width: 20rem;
							flex-basis: 71%;
							margin-right: auto;
							.not-found {
								margin-top: 1rem;
								color: #c0c0c0;
							}
						}
						.button-container {
							flex-basis: 26%;
							margin-right: 3%;
						}
					}
				}
				&.clear-url-settings {
					.button-container {
						margin: 2% auto 5% 0;
					}
				}
				&.ecommerce-settings {
					width: 97%;
					flex-direction: column;
					&--is-ecommerce {
						max-width: 100%;
						width: 100%;
						margin-left: 0;
						margin: unset;
						margin-bottom: 2rem;
						p {
							width: 40rem;
							max-width: 100%;
							flex-basis: 70%;
						}
						input {
							margin-right: 30rem;
							margin-top: 3px;
							cursor: pointer;
						}
					}
				}
			}
			border-radius: $medium-border;
			.section-divider {
				margin: 1rem 0rem 1rem 1rem;
				border: 0;
				border-top: 1px solid #e0e0e0;
				width: 97%;
			}
			&-delete-project {
				margin-top: 1rem;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: $padding-small;
				align-items: flex-start;
				flex-direction: column;
				background-color: $white; // Add white background
				border-radius: $medium-border; // Add rounded corners
				box-shadow: 7px -7px 10px -10px rgba(59, 94, 218, 0.07),
					-5px 2px 9px -9px rgba(59, 94, 218, 0.05); // Add subtle shadow
				p {
					font-size: 1.6rem;
					margin-left: 1rem;
				}

				.button-container {
					margin: 1.5rem 0rem 1rem 1rem;
				}
			}
			&.scrollable {
				overflow-y: scroll;
				max-height: 90vh;
				overflow-x: hidden;
				.product-reference {
					p {
						margin-left: 1rem;
					}
					&__input {
						width: 97%;
						display: flex;
						align-items: flex-start;
						.MuiSvgIcon-root {
							font-size: 2.5rem;
							color: $dark-blue;
						}
						.input__container {
							.input__row {
								width: 100%;
							}
						}
						.icon-red {
							.MuiSvgIcon-root {
								font-size: 2.5rem;
								color: $red;
							}
						}
					}
					&__add {
						display: flex;
						justify-content: center;
						cursor: pointer;
						width: 1%;
						margin-left: 50%;
					}
					&__buttons {
						display: flex;
						justify-content: center;
					}
					&__button {
						margin: 0rem;
						width: 17rem;
						margin-left: 1rem;
						margin-bottom: 2rem;
					}
				}
			}
			background-color: $white;
			margin-top: 1.5rem;
			display: flex;
			flex-direction: column;
			.button-white {
				background-color: $dark-blue;
			}

			.button-text {
				color: $white;
				:disabled {
					color: $turqoise;
				}
			}

			.notification-holder {
				display: flex;
				justify-content: space-between;
				align-items: flex-end;
				margin-bottom: 0.5rem;
				&-left {
					display: flex;
					margin-left: $padding-small;
					align-items: center;
					justify-content: center;
					margin-bottom: 0;
					p {
						margin-bottom: 0;
					}
				}
				&-right {
					display: flex;
					align-items: center;
				}
				svg {
					font-size: 2rem;
					margin-right: 1.5rem;
				}
				.remove-notification {
					margin-bottom: 0;
					margin-right: 2rem;
					font-weight: bold;
				}
				.remove-notification:hover {
					cursor: pointer;
					color: $red;
				}
			}
			&-title {
				font-family: $lato;
				font-weight: bold;
				padding: $padding-small;
				text-transform: initial;
				margin-bottom: 0;
				font-size: 2.8rem;
				&--remember-conversion-value {
					font-size: 1.2rem;
					margin-left: 1.5rem;
				}
				&-chip {
					background-color: $dark-blue !important;
					.MuiChip-label {
						color: $white !important;
					}
				}
				&__project-type {
					@include container-default(
						$flex-direction: row,
						$align-items: flex-start,
						$justify-content: space-between
					);
					&--container {
						&--value {
							color: $dark-blue;
						}
					}
				}
			}
			&-sub-title {
				font-family: $lato;
				font-weight: bold;
				padding: $padding-small;
				text-transform: initial;
				margin-bottom: 0;
				font-size: 2.4rem;
			}
			&-sub-sub-title {
				font-family: $lato;
				font-weight: bold;
				padding: $padding-small;
				text-transform: initial;
				margin-bottom: 0;
				font-size: 2rem;
			}
			&__input-panel {
				padding: 1rem 3rem;
				margin-bottom: 3rem;
				background-color: $white;
				border-radius: $small-border;
				.horizontal-inputs {
					.button-container {
						margin: 0 !important;
					}
					display: flex;
					height: 12rem;
					border-bottom: 2px solid $background-gray;
					margin-left: 1rem;
					.input__container:last-child {
						margin-right: 0;
					}
					.input__container:first-child {
						margin-left: 0;
					}
				}
				.horizontal-inputs:last-child {
					border: none;
				}
				.small {
					width: 50%;
				}
				.row {
					display: flex;
					align-items: center;
					justify-content: space-between;
					height: 5rem;
					width: 100%;
					p {
						margin: 0.2rem 1rem 0 0;
					}
				}
				&__new-contributor {
					&__inputs {
						@include container-default($flex-direction: row);
						align-items: flex-start;
						height: 15rem;
					}
					&__item {
						margin: 1rem;
						display: flex;
						flex-direction: column;
						width: 100%;
						margin-bottom: 0.75rem;
						position: relative;
						border-radius: 6px;
						p {
							margin: 1rem;
							display: flex;
							flex-direction: column;
							width: 100%;
							margin-bottom: 0.75rem;
							position: relative;
							border-radius: 6px;
						}
					}
					&__save-button {
						max-width: 20rem;
						margin-left: auto;
					}
					.input__container {
						margin-top: 1rem;
						.input__row {
							width: 100%;
							// .input__border.has-error {
							// 	border: 0.125rem solid rgba(0, 25, 116, 0.19);
							// }
						}
						.input__error {
							margin-top: 1rem;
						}
					}
				}
				&__contributor-list {
					&__title {
						margin-bottom: 2rem;
					}
					&__item {
						@include container-default(
							$flex-direction: row,
							$justify-content: flex-start,
							$align-items: flex-start
						);
						flex-wrap: wrap;
						margin-bottom: 1rem;
						p {
							font-size: 1.6rem;
							font-weight: 400;
							margin-right: 1rem;
							text-transform: none;
							margin-top: 1rem;
						}
						&__icon {
							height: 1em;
							cursor: pointer;
							flex-basis: 5%;
							min-width: 5%;
							margin-top: 1rem;
							margin-left: auto;
							color: red;
							&.add {
								color: $dark-blue;
								&.disabled {
									color: rgba($dark-blue, 0.5);
									cursor: not-allowed;
								}
							}
						}
						&__row {
							flex-basis: 12.5%;
							min-width: 12.5%;
							height: 100%;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
							&.title {
								font-weight: bold;
							}
							&.email {
								flex-basis: 35%;
								display: block;
								cursor: pointer;
							}
							&.last {
								flex-basis: 25%;
								max-width: 25%;
								min-width: 25%;
								&.select {
									overflow: unset;
								}
							}
							&.input {
								margin-top: 0;
								.input__container {
									margin: unset;
									.input__row {
										width: 100%;
									}
								}
							}
						}
					}
				}
			}
			.MuiAccordionSummary-gutters {
				.MuiAccordionSummary-content {
					// font-size: 2.8rem;
					// margin-left: 1rem;
					margin-top: 2rem;
				}
			}
		}
	}
}
