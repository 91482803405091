.ai-content-in-bulk {
	padding: 3rem;
	&__redirect {
		display: flex;
		align-items: center;
		p {
			font-size: 1.8rem;
		}
		a {
			margin-left: 0.5rem;
			cursor: pointer;
			color: #61c9fb;
			text-decoration: underline;
		}
		.button-container {
			margin: 0;
		}
	}
	&__command-creator {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		min-width: 100%;
	}
	&__select-container {
		margin: 1rem;
		min-width: 25rem;
	}
	&__input {
		display: flex;
		align-items: center;
		min-width: 25rem;
		margin-right: 2rem;
		margin-left: 2rem;
		&.subject-input {
			p {
				margin-right: 3rem;
				margin-left: -1rem;
			}
			.input__container {
				margin-top: 0.6rem;
			}
		}
		&.additional-input {
			margin-left: 0;
			.input__container {
				margin-left: 0;
			}
		}
	}
	&__excel-import {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		min-width: 100%;
		&--button {
			cursor: pointer;
			margin-left: auto;
			input[type='file'] {
				display: none;
			}
			border: 1px solid $dark-blue;
			border-radius: $small-border;
			padding: 1rem;
			margin-bottom: 1rem;
			margin-right: 1.5rem;
			p {
				color: $white;
			}
			background-color: $dark-blue;
			&.disabled {
				background-color: grey;
				pointer-events: none;
			}
		}
	}
	&__example-excel {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	&__bulk-generation-list {
		.MuiDataGrid-root {
			border: none;
			.MuiDataGrid-cell:focus,
			.MuiDataGrid-cell:focus-within,
			.MuiDataGrid-columnHeader:focus,
			.MuiDataGrid-columnHeader:focus-within {
				outline: none;
			}
		}
		.MuiInputBase-root-MuiDataGrid-editInputCell input {
			background-color: #fff;
		}
		.MuiDataGrid-root .MuiDataGrid-iconSeparator {
			font-size: 2.5rem;
		}
		.MuiDataGrid-root {
			.MuiDataGrid-menuOpen {
				margin-right: 0.5rem;
			}
		}
		.MuiDataGrid-menuIcon {
			.MuiDataGrid-menuOpen {
				margin-right: 0.5rem;
			}
		}
		.MuiDataGrid-root .MuiDataGrid-columnHeader:hover .MuiDataGrid-menuIcon {
			margin-right: 0.5rem;
		}
		.MuiDataGrid-columnHeaderTitleContainer {
			.input__container {
				margin: 1rem 0rem;
				.input__row {
					input {
						padding: 0rem;
						font-weight: bold;
						text-transform: capitalize;
					}
				}
			}
		}
		.MuiTablePagination-root {
			p {
				margin-bottom: 0rem;
			}
			.MuiInputBase-root {
				background-color: $white;
			}
		}
		.MuiTablePagination-selectLabel {
			font-size: 1.6rem;
		}
		.MuiTablePagination-displayedRows {
			font-size: 1.6rem;
		}
		.MuiDataGrid-menuList {
			.MuiButtonBase-root {
				font-size: 1.6rem;
			}
		}
	}
	&__generate-button {
		display: flex;
		min-width: 100%;
		justify-content: space-between;
		align-items: flex-start;
		flex-direction: column;
		.button-container {
			margin: 1rem;
			margin-left: auto;
		}
		p {
			margin-bottom: 1rem;
			margin-left: 0.5rem;
		}
		&__format-selection {
			margin-left: auto;
			display: flex;
			align-items: flex-start;
			width: 100%;
			flex-direction: row;
			p {
				margin-right: 1rem;
			}
			&__select-container {
				display: flex;
				align-items: center;
				width: 100%;
				justify-content: flex-start;
				margin-top: 1rem;
				margin-right: 1rem;
				flex: 1;
				&.languages {
					flex: 2;
					margin-right: 2rem;
				}
				&__selector {
					flex: 1;
				}
			}
		}
	}
	p {
		margin-bottom: 0rem;
	}
}

.bulk-generation-status {
	border-radius: 2rem;
	padding: 0.5rem 1.2rem;
	&.status-0 {
		background-color: #fff0bc;
		color: #b08900;
	}
	&.status-1 {
		background-color: #d1ffc1;
		color: #4e9934;
	}
}
