.google-change-events {
	&__container {
		height: 100%;
		width: 100%;
		overflow-y: scroll;
		@include box-shadow-light();
		@include container-default(
			$flex-direction: column,
			$align-items: flex-start
		);
		margin-top: 2rem;
		padding-top: 1rem;
		&__chart-header {
			@include container-default(
				$flex-direction: row,
				$justify-content: flex-start,
				$align-items: center
			);
			margin: 1rem;
			margin-bottom: 3rem;
			h5 {
				margin-right: 2rem;
			}
			&__select {
				min-width: 30rem;
				&--selector {
					height: unset;
				}
			}
		}
		&__total-values {
			display: grid;
			grid-template-columns: repeat(6, 1fr);
			margin-bottom: 1rem;
			&__item {
				padding: 1rem;
				margin-left: 1rem;
				@include box-shadow-light();
				border-radius: $small-border;
				&:hover {
					cursor: pointer;
				}
				&__switch {
					@include container-default(
						$flex-direction: row,
						$justify-content: flex-start
					);
					p {
						color: $white;
						font-weight: 400;
						font-size: 1.4rem;
						margin-bottom: 0;
					}
				}
				&__value {
					color: $white;
					font-size: 2rem;
					margin-bottom: 0;
				}
			}
		}
		&__chart {
			width: 90%;
			height: 500px;
			margin: 0 auto;
		}
		&__data-headers {
			display: grid;
			grid-template-rows: repeat(1, 1fr);
			grid-auto-columns: auto;
			column-gap: 0.5rem;
			//overflow-y: scroll;

			p {
				min-width: 18%;
				text-align: center;
				margin-bottom: 0;
				&:hover {
					cursor: pointer;
				}
				padding: 0.25rem;
			}
			&--active {
				background-color: $dark-blue;
				color: $white;
			}
		}
		&__data-grid {
			height: 800px;
			width: 100%;
			.MuiDataGrid-cell {
				overflow: scroll;
			}
			&__cell {
				padding: 0.5rem 0;
				p,
				ul {
					text-overflow: ellipsis;
					max-width: 100%;
					font-size: 1.2rem;
					margin-bottom: 0;
					white-space: pre-wrap;
				}
				p {
					font-size: 1.3rem;
					word-wrap: break-word;
				}
				&.values {
					max-height: 80px;
					overflow: scroll;
				}
			}
			.MuiInputBase-root {
				background-color: $white;
				font-size: 1.3rem;
			}
			.MuiTablePagination-displayedRows,
			.MuiTablePagination-selectLabel {
				font-size: 1.3rem;
			}
			.MuiSvgIcon-root {
				font-size: 2.5rem;
			}
		}
	}
}
