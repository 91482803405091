.custom-table {
	@include container-default();

	&__headers {
		@include container-default(
			$flex-direction: row,
			$justify-content: flex-start
		);
		width: 100%;
		border-bottom: 2px solid $dark-gray;
		margin-bottom: 2rem;
		:first-child {
			flex-basis: 5%;
			margin-left: 0;
		}
		&--item {
			font-weight: 500;
			margin-left: 10rem;
			flex-basis: 20%;
			cursor: pointer;
			font-size: 1.5rem;
			color: $gray;
			&--icon {
				font-size: 2rem;
			}
			.css-tzssek-MuiSvgIcon-root {
				height: 2rem;
				width: 2rem;
			}
		}
	}

	&__keyword-table {
		@include container-default();
		width: 100%;

		&__row {
			@include container-default(
				$flex-direction: column,
				$justify-content: flex-start
			);
			&.analysis {
				&:nth-child(even) {
					background-color: $light-gray;
				}
				&.own {
					p {
						font-weight: bold;
					}
				}
			}

			width: 100%;
			cursor: pointer;
			margin-bottom: 0;

			&__pagination {
				width: 90%;
				.MuiTablePagination-root {
					.MuiInputBase-root {
						background-color: $white;
					}
				}
				.MuiButtonBase-root {
					display: flex;
				}
				.MuiTablePagination-displayedRows {
					font-size: 1.6rem;
					margin-bottom: 0;
				}
				.MuiTablePagination-selectLabel {
					font-size: 1.6rem;
					margin-bottom: 0;
				}
			}

			&--divider {
				width: 90%;
				color: #034079;
				&.analysis {
					width: 98%;
				}
			}

			&--headers {
				@include container-default(
					$flex-direction: row,
					$justify-content: flex-start
				);
				width: 100%;
				p {
					margin-bottom: 0;
				}
				&--item {
					margin-left: 10rem;
					flex-basis: 20%;
					font-weight: 400;

					&--icon {
						width: 12px;
						height: 12px;
						margin-right: 1rem;
						&.person {
							font-size: unset;
						}
					}

					&.positive {
						color: #10a003;
						font-weight: bold;
					}

					&.negative {
						color: #b70606;
						font-weight: bold;
					}

					&.new {
						flex-basis: 15%;
					}
				}
				&--icon {
					width: 50px;
					height: 50px;
				}
				&.first {
					flex-basis: 5%;
					margin-left: 0;
				}

				&.second {
					flex-basis: 25%;
					font-weight: 500;
				}
			}
			&--expanded {
				@include container-default(
					$flex-direction: column,
					$justify-content: flex-start
				);
				width: 100%;
				margin-bottom: 1rem;
				overflow: hidden;

				&[aria-expanded='false'] {
					max-height: 0px;
					transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
				}
				&[aria-expanded='true'] {
					max-height: 400px;
					transition: max-height 0.5s cubic-bezier(1, 0, 1, 0);
					overflow: scroll;
				}
				&.fullsize {
					max-height: unset;
					overflow: hidden;
				}
				&.header {
					font-weight: bold;
				}

				&-transition {
					transition: height 2s linear;
				}

				&--date {
					width: 100%;
					color: $dark-blue;
				}

				&--items {
					@include container-default(
						$flex-direction: row,
						$justify-content: flex-start
					);
					p {
						font-size: 1.3rem;
					}
					width: 100%;
					&.first {
						flex-basis: 5%;
						margin-left: 1rem;
						font-size: 1rem;
					}
					&.second {
						flex-basis: 25%;
						font-weight: 500;
					}
				}
				&--rows {
					&--keyword-section {
						display: flex;
						flex-direction: column;
						justify-content: flex-start;
						padding: 20px;
						p {
							font-size: 20px;
						}
						&--keyword-chips {
							display: flex;
							flex-wrap: wrap;
							margin-top: 10px;
							&--keyword-chip {
								background-color: #fff !important;
								border: 1.5px solid #182550;
								border-radius: 100px;
								font-size: 1.4rem;
								font-weight: bold;
								text-align: center;
								padding: 0.5rem;
								margin-right: 1rem;
								margin-bottom: 1rem;
							}
						}
					}
				}
			}
			&__analysis {
				@include container-default(
					$flex-direction: row,
					$justify-content: flex-start
				);
				width: 100%;

				border-bottom: 1px solid #034079;
				padding: 0.5rem 0.5rem;

				p {
					margin-bottom: 0;
					text-align: center;
				}
				&__item {
					&.domain {
						min-width: 20%;
						text-align: left;
					}
					flex-basis: 20%;
					font-weight: 400;
				}
				Button {
					flex-basis: 20%;
					font-family: 'Lato', sans-serif;
					font-style: normal;
					font-size: 1.1rem;
					font-weight: 600;
					color: #182550;
				}
			}
		}
	}
}
